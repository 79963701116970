import "./App.css";
import SketchPage from "./components/SketchPage";

const MAX_Z_DEPTH = 1000;

function App() {
  return (
    <div className="App">
      <SketchPage></SketchPage>
    </div>
  );
}

export default App;

// import { useEffect, useRef } from "react";
// import * as THREE from "three";

// const App = () => {
//   const mountRef = useRef(null);

//   const sceneRef = useRef(null);

//   useEffect(() => {
//     var scene = new THREE.Scene();
//     var camera = new THREE.PerspectiveCamera(
//       75,
//       window.innerWidth / window.innerHeight,
//       0.1,
//       1000
//     );
//     var renderer = new THREE.WebGLRenderer();

//     renderer.setSize(window.innerWidth, window.innerHeight);
//     mountRef.current.appendChild(renderer.domElement);

//     var geometry = new THREE.BoxGeometry(1, 1, 1);
//     var material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
//     var cube = new THREE.Mesh(geometry, material);

//     scene.add(cube);
//     camera.position.z = 5;

//     var animate = function () {
//       requestAnimationFrame(animate);
//       cube.rotation.x += 0.01;
//       cube.rotation.y += 0.01;
//       renderer.render(scene, camera);
//     };

//     let onWindowResize = function () {
//       camera.aspect = window.innerWidth / window.innerHeight;
//       camera.updateProjectionMatrix();
//       renderer.setSize(window.innerWidth, window.innerHeight);
//     };

//     window.addEventListener("resize", onWindowResize, false);

//     animate();

//     sceneRef.current = scene;
//     return () => mountRef.current.removeChild(renderer.domElement);
//   }, []);

//   return (
//     <div>
//       <div ref={mountRef}></div>
//       <button
//         onClick={() => {
//           alert("here");

//           var geometry = new THREE.BoxGeometry(2, 2, 2);
//           var material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
//           var cube = new THREE.Mesh(geometry, material);

//           sceneRef.current.add(cube);
//         }}
//       >
//         test
//       </button>
//     </div>
//   );
// };

// export default App;
