import "./Canvas.css";
import * as THREE from "three";
import { useEffect, useRef, useState } from "react";

import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { OutlinePass } from "three/examples/jsm/postprocessing/OutlinePass.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader.js";
import { Button, Icon, IconButton, Slider } from "@mui/material";
import {
  AddShoppingCartRounded,
  CheckCircleOutline,
  Gesture,
  GestureOutlined,
  Pentagon,
  PentagonOutlined,
  ShareSharp,
} from "@mui/icons-material";

import { Line2 } from "../../lines/Line2";
import { LineMaterial } from "../../lines/LineMaterial";
import { LineGeometry } from "../../lines/LineGeometry";
import {
  LINE_SLIDER_MAX,
  LINE_SLIDER_MIN,
  MAX_LINE_WIDTH,
  MIN_LINE_WIDTH,
} from "../../utils/constants";

// import { MeshLine, MeshLineMaterial, MeshLineRaycast } from "three.meshline";

const MAX_Z_DEPTH = 1000;
const WORKING_LINE_Z_DEPTH = 0.5;
const MAX_POINTS = 5000;
const NUM_ELEMENTS_PER_POINT = 3;

function ArtCanvas({
  redValue,
  greenValue,
  blueValue,
  isMeshSelected,
  syncIsMeshSelected,
  syncIsLoaded,
  isSelectingColor,
  syncIsSelectingColor,
  isSelectingShape,
  syncIsSelectingShape,
  syncRedValue,
  syncGreenValue,
  syncBlueValue,
  resettingCanvas,
  syncResettingCanvas,
  touchDisabled,
  sceneRef,
  selectedFile,
  rendererRef,
  updateHSV,
  autocompleteShape,
}) {
  const canvasRef = useRef(null);
  // const [scene, setScene] = useState(null);

  const cameraRef = useRef(null);

  const meshGroupRef = useRef(null);
  const workingGroupRef = useRef(null);
  const workingLineRef = useRef(null);

  const backgroundMeshRef = useRef(null);

  const workingMeshRef = useRef(null);

  const workingLineMeshRef = useRef(null);

  const workingShapesRef = useRef(null);

  const [strokeIndexCount, setStrokeIndexCount] = useState(0);

  const [renderer, setRenderer] = useState(null);
  const [composer, setComposer] = useState(null);
  // const [camera, setCamera] = useState(null);
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  // const [backgroundMesh, setBackgroundMesh] = useState(null);
  const [effectFXAA, setEffectFXAA] = useState(null);
  const [previousPoint, setPreviousPoint] = useState(null);
  const [workingCircle, setWorkingCircle] = useState(null);
  const [workingLineSource, setWorkingLineSource] = useState(null);
  // const [workingLine, setWorkingLine] = useState(null);
  const [zCount, setZCount] = useState(1);
  const [raycaster, setRaycaster] = useState(null);
  const [pIndex, setPIndex] = useState(0);
  const [sliderValue, setSliderValue] = useState(0);

  const [selectedType, setSelectedType] = useState("fill");
  const [selectedLineWidth, setSelectedLineWidth] = useState(1);

  const selectedMeshRef = useRef(null);

  const prevSelectedMeshRef = useRef(null);

  const isMouseDownRef = useRef(false);

  // useEffect(() => {
  //   syncIsMeshSelected(selectedMeshRef.current != null);
  // }, [selectedMeshRef.current]);
  useEffect(() => {
    if (selectedMeshRef.current != null) {
      selectedMeshRef.current.material.color.r = redValue;
      selectedMeshRef.current.material.color.g = greenValue;
      selectedMeshRef.current.material.color.b = blueValue;

      rerender();
    }
  }, [redValue, greenValue, blueValue]);

  useEffect(() => {
    if (resettingCanvas) {
      clearWorkingLine();
      setSelectedMesh(backgroundMeshRef.current);
      meshGroupRef.current.clear();
      syncResettingCanvas(false);
    }
  }, [resettingCanvas]);

  useEffect(() => {
    if (meshGroupRef.current == null) return;

    meshGroupRef.current.remove(workingLineMeshRef.current);
    meshGroupRef.current.remove(workingMeshRef.current);
    if (selectedType === "fill") {
      meshGroupRef.current.add(workingMeshRef.current);
    } else if (selectedType === "line") {
      // meshGroupRef.current.remove(workingMeshRef.current);
      meshGroupRef.current.add(workingLineMeshRef.current);
    }
  }, [selectedType]);

  useEffect(() => {
    if (selectedFile == null) return;

    const loader = new THREE.ObjectLoader();

    // try {
    const obj = loader.parse(selectedFile);

    const groupsWithChildren = obj.children.filter(
      (c) => c != null && c.type === "Group" && c.children?.length > 0
    );
    const backgrounds = obj.children.filter(
      (c) => c != null && c.type === "Mesh"
    );

    if (groupsWithChildren.length === 0 || backgrounds.length === 0) return;
    const groupToAdd = groupsWithChildren[0];

    const background = backgrounds[0];

    sceneRef.current.remove(backgroundMeshRef.current);

    background.position.z = -MAX_Z_DEPTH + 1;
    sceneRef.current.add(background);
    backgroundMeshRef.current = background;

    sceneRef.current.remove(meshGroupRef.current);

    sceneRef.current.add(groupToAdd);
    meshGroupRef.current = groupToAdd;

    // for (const child of groupToAdd.children) {

    //   meshGroupRef.current.add(child);
    // }
    const zPos = groupToAdd.children.map((c) => c.position.z);

    const maxZPos = Math.max(...zPos);

    setZCount(maxZPos + 0.001);
    // } catch (error) {
    //   console.error(error);
    // }
  }, [selectedFile]);

  useEffect(() => {
    if (workingLineMeshRef.current != null) {
      workingLineMeshRef.current.material.linewidth =
        calculateLineWidth(selectedLineWidth);
      rerender();
    }
  }, [selectedLineWidth]);

  useEffect(() => {
    const newType = sliderValue >= MAX_LINE_WIDTH / 3 ? "line" : "fill";
    if (selectedType !== newType);
    setSelectedType(newType);

    setSelectedLineWidth(Math.max(0, sliderValue - MAX_LINE_WIDTH / 3));
  }, [sliderValue]);

  const rerender = () => {
    rendererRef.current.render(sceneRef.current, cameraRef.current);
  };

  const setSelectedMesh = (newMesh) => {
    prevSelectedMeshRef.current = selectedMeshRef.current;
    selectedMeshRef.current = newMesh;

    syncIsMeshSelected(newMesh != null); //triggers update
  };

  useEffect(() => {
    const raycaster = new THREE.Raycaster();
    setRaycaster(raycaster);

    const canvasBoundingBox = canvasRef.current.getBoundingClientRect();

    let width = canvasBoundingBox.width;
    let height = canvasBoundingBox.height;

    // let width = 250;
    // let height = 250;

    const aspect = width / height;
    const frustum = height;

    // camera
    const left = (frustum * aspect) / -2;
    const right = (frustum * aspect) / 2;
    const top = frustum / 2;
    const bottom = frustum / -2;
    const near = 1;
    const far = 2 * MAX_Z_DEPTH + 1;

    // const camera = new THREE.OrthographicCamera(
    //   left,
    //   right,
    //   top,
    //   bottom,
    //   near,
    //   far
    // );
    const camera = new THREE.OrthographicCamera(
      width / -2,
      width / 2,
      height / 2,
      height / -2,
      1,
      2 * MAX_Z_DEPTH + 1
    );
    camera.position.z = MAX_Z_DEPTH + 1;

    const scene = new THREE.Scene();

    const meshGroup = new THREE.Group();
    scene.add(meshGroup);
    meshGroupRef.current = meshGroup;

    const workingGroup = new THREE.Group();
    scene.add(workingGroup);
    workingGroupRef.current = workingGroup;

    // setScene(scene);

    let devicePixelRatio = 1;
    if (window.devicePixelRatio !== undefined) {
      devicePixelRatio = window.devicePixelRatio;
    }

    // setup doc

    // renderer stuff
    // const canvas = document.getElementById("canvas");
    // const container = document.getElementById("canvas");

    const renderer = new THREE.WebGLRenderer({ preserveDrawingBuffer: true });
    canvasRef.current.appendChild(renderer.domElement);

    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(width, height);
    renderer.sortElements = false;

    // postprocessing
    // const composer = new EffectComposer(renderer);
    // composer.setSize(width, height);
    // let renderPass = new RenderPass(scene, camera);
    // composer.addPass(renderPass);

    // const outlinePass = new OutlinePass(
    //   new THREE.Vector2(width, height),
    //   scene,
    //   camera
    // );
    // outlinePass.edgeStrength = 0.7;
    // outlinePass.edgeGlow = 0;
    // outlinePass.edgeThickness = 1;
    // outlinePass.visibleEdgeColor.set(0xffffff);
    // outlinePass.hiddenEdgeColor.set(0x000000);
    // composer.addPass(outlinePass);

    // const effectFXAA = new ShaderPass(FXAAShader);
    // effectFXAA.uniforms["resolution"].value.set(
    //   1 / (width * devicePixelRatio),
    //   1 / (height * devicePixelRatio)
    // );
    // effectFXAA.renderToScreen = true;
    // composer.addPass(effectFXAA);
    // setEffectFXAA(effectFXAA);
    // // render();
    // renderer.render(scene, camera);

    // setComposer(composer);
    rendererRef.current = renderer;
    cameraRef.current = camera;
    sceneRef.current = scene;
    // function render() {
    //   if (rendererRef.current != null) {
    //     rendererRef.current.render(sceneRef.current, cameraRef.current);
    //   }
    // }
    let squareMesh = new THREE.PlaneGeometry(100, 100, 100);
    let startColor = new THREE.Color(redValue, greenValue, blueValue);

    let squareMaterial = new THREE.MeshBasicMaterial({ color: startColor });
    const backgroundMesh = new THREE.Mesh(squareMesh, squareMaterial);

    backgroundMesh.position.z = -MAX_Z_DEPTH + 1;
    backgroundMesh.scale.x = width;
    backgroundMesh.scale.y = height;
    scene.add(backgroundMesh);

    backgroundMeshRef.current = backgroundMesh;
    setSelectedMesh(backgroundMesh);

    // syncIsMeshSelected(true);

    // var geometry = new THREE.BoxGeometry(100, 100, 100);
    // var material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
    // var cube = new THREE.Mesh(geometry, material);
    // scene.add(cube);

    var animate = function () {
      requestAnimationFrame(animate);
      // backgroundMesh.rotation.x += 0.01;
      // backgroundMesh.rotation.y += 0.01;
      renderer.render(scene, camera);
    };
    // function animate() {
    //   requestAnimationFrame(animate);
    //   render();
    // }
    animate();

    window.onresize = () => {
      if (rendererRef.current != null) {
        var w = canvasRef.current.offsetWidth;
        var h = canvasRef.current.offsetHeight;
        if (rendererRef.current != undefined) {
          rendererRef.current.setSize(w, h);
          // composer.setSize(w, h);
          // effectFXAA.uniforms["resolution"].value.set(1 / w, 1 / h);
        }

        cameraRef.current.left = -w / 2;
        cameraRef.current.right = w / 2;
        cameraRef.current.top = h / 2;
        cameraRef.current.bottom = -h / 2;
        cameraRef.current.updateProjectionMatrix();

        backgroundMeshRef.current.scale.x = w;
        backgroundMeshRef.current.scale.y = h;

        setHeight(h);
        setWidth(w);
      }
    };

    return () => canvasRef.current.removeChild(renderer.domElement);
  }, []);

  function clearWorkingLine() {
    if (workingCircle) {
      workingGroupRef.current.clear();
      workingLineRef.current = null;
      // sceneRef.current.remove(workingCircle);
      setWorkingCircle(null);
      setPreviousPoint(null);
      setWorkingLineSource(null);
      setPIndex(0);
    }
  }

  function addDebugPt(vec, c) {
    c = c || 0xffffff;
    var geometry = new THREE.CircleGeometry(4, 10);
    var material = new THREE.MeshBasicMaterial({ color: c });
    var pt = new THREE.Mesh(geometry, material);
    pt.position.x = vec.x;
    pt.position.y = vec.y;
    pt.position.z = MAX_Z_DEPTH;
    return pt;
  }

  function startNewLine(vector) {
    if (selectedMeshRef.current != null) {
      setSelectedMesh(null);
      // syncIsMeshSelected(false);
    }

    clearWorkingLine();
    const workingLineSource = new THREE.Vector3(vector.x, vector.y);
    setWorkingLineSource(workingLineSource);

    const workingCircle = addDebugPt(vector, 0xffffff);
    workingGroupRef.current.add(workingCircle);
    setWorkingCircle(workingCircle);

    // var geometry = new THREE.BufferGeometry();

    // const points = [];
    // points.push(new THREE.Vector3(vector.x, vector.y, 0));
    // points.push(new THREE.Vector3(vector.x, vector.y, 0));
    // points.push(new THREE.Vector3(vector.x, vector.y, 0));

    const geometry = new THREE.BufferGeometry();
    var pos = new Float32Array(MAX_POINTS * NUM_ELEMENTS_PER_POINT); // 3 vertices per point
    geometry.setAttribute(
      "position",
      new THREE.BufferAttribute(pos, NUM_ELEMENTS_PER_POINT)
    );
    // drawCount = 0; // draw the first 2 points, only
    geometry.setDrawRange(0, 0);

    var material = new THREE.LineBasicMaterial({
      color: 0x000000,
    });
    const workingLine = new THREE.Line(geometry, material);
    workingLine.position.z = zCount + 1;

    workingGroupRef.current.add(workingLine);

    return workingLine;
  }

  // function intersectPt(pt) {
  //   raycaster.setFromCamera(pt, cameraRef.current);
  //   var intersects = raycaster.intersectObjects([sceneRef.current], true);

  //   if (intersects.length > 0) {
  //     intersects.sort((a, b) => b.object.position.z - a.object.position.z);
  //     return intersects[0].object;
  //   }
  // }

  function canvasPt(v, flipY = true) {
    var bb = canvasRef.current.getBoundingClientRect();
    var flip = flipY ? -1 : 1;
    return new THREE.Vector3(
      (v.x / bb.width) * 2,
      ((flip * v.y) / bb.height) * 2,
      0.5
    );
  }

  const calculateLineWidth = (val) => {
    const sizeLength = (LINE_SLIDER_MAX - LINE_SLIDER_MIN) / 3;
    const addedWidth =
      (Math.exp(val / 4) / sizeLength) * (MAX_LINE_WIDTH - MIN_LINE_WIDTH);
    // const exp =;
    return addedWidth / 30 + MIN_LINE_WIDTH;
  };

  const removePoints = (n) => {
    if (workingLineRef.current == null) return;

    var pos = workingLineRef.current.geometry.attributes.position.array;
    let currPIndex = pIndex;

    for (let i = 0; i < n * 3; i++) {
      if (currPIndex === 0) break;
      pos[currPIndex--] = 0;
    }

    workingLineRef.current.geometry.setDrawRange(
      0,
      currPIndex / NUM_ELEMENTS_PER_POINT
    );
    workingLineRef.current.geometry.attributes.position.needsUpdate = true;

    createShape();
    makeL(currPIndex);

    setPIndex(currPIndex);
  };

  const addPoint = (x, y) => {
    if (workingLineRef.current == null) {
      var vec = new THREE.Vector3(x, y, WORKING_LINE_Z_DEPTH);
      workingLineRef.current = startNewLine(vec);
      // sets line color based on background
      // var intersection = intersectPt(canvasPt({ x: x, y: y }));
      // if (intersection != undefined) {
      //   var hsl = new THREE.Color();
      //   hsl = intersection.material.color.getHSL(hsl);
      //   if (hsl.l < 0.4) {
      //     currLine.material.color.r = 1.0;
      //     currLine.material.color.g = 1.0;
      //     currLine.material.color.b = 1.0;
      //   }
      // }
    }

    var pos = workingLineRef.current.geometry.attributes.position.array;
    let currPIndex = pIndex;
    pos[currPIndex++] = x;
    pos[currPIndex++] = y;
    pos[currPIndex++] = 0;
    workingLineRef.current.geometry.setDrawRange(
      0,
      currPIndex / NUM_ELEMENTS_PER_POINT
    );
    workingLineRef.current.geometry.attributes.position.needsUpdate = true;

    makeL(currPIndex);
    setPIndex(currPIndex);

    createShape();
  };

  const makeL = (currPIndex) => {
    var pos = workingLineRef.current.geometry.attributes.position.array;

    const positions = [];
    const colors = [];

    // const points = GeometryUtils.hilbert3D(
    //   new THREE.Vector3(0, 0, 0),
    //   20.0,
    //   1,
    //   0,
    //   1,
    //   2,
    //   3,
    //   4,
    //   5,
    //   6,
    //   7
    // );

    // const points = [
    //   new THREE.Vector3(0, 0, 0),
    //   new THREE.Vector3(100, 100, 0),
    // ];
    // const spline = new THREE.CatmullRomCurve3(points);
    // const divisions = Math.round(12 * points.length);
    // const point = new THREE.Vector3();
    // const color = new THREE.Color();

    // for (let i = 0, l = divisions; i < l; i++) {
    //   const t = i / l;

    //   spline.getPoint(t, point);
    //   positions.push(point.x, point.y, point.z);

    //   color.setHSL(1, 1.0, 0.5);
    //   colors.push(color.r, color.g, color.b);
    // }

    // Line2 ( LineGeometry, LineMaterial )

    const geometry = new LineGeometry();
    geometry.setPositions(pos.slice(0, currPIndex));
    // geometry.setColors(colors);

    const lineWidth = calculateLineWidth(selectedLineWidth);

    const matLine = new LineMaterial({
      color: 0xffffff,
      linewidth: lineWidth, // in world units with size attenuation, pixels otherwise
      vertexColors: false,
      opacity: 0.15,
      //resolution:  // to be set by renderer, eventually
      dashed: false,
      alphaToCoverage: false,
    });

    const line = new Line2(geometry, matLine);
    line.computeLineDistances();
    line.scale.set(1, 1, 1);
    line.position.z = zCount;
    setZCount(zCount + 0.001);
    if (workingLineMeshRef.current != null) {
      meshGroupRef.current.remove(workingLineMeshRef.current);
    }
    if (selectedType === "line") {
      meshGroupRef.current.add(line);
    }
    workingLineMeshRef.current = line;
  };

  function convertToCanvasSpace(vec) {
    var bb = canvasRef.current.getBoundingClientRect();
    const normalizedX = (vec.x - bb.left) / bb.width - 0.5;
    const normalizedY = -(vec.y - bb.top) / bb.height + 0.5;
    // vec.x -= bb.left;
    // vec.y -= bb.top;
    // vec.set(
    //   ((vec.x - bb.left) / bb.width) * 2 - 1,
    //   (-(vec.y - bb.top) / bb.height) * 2 + 1,
    //   0.5
    // );
    // return vec;
    vec.set(normalizedX * bb.width, normalizedY * bb.height);
    return vec;
  }

  function getMousePosition(e) {
    var vec = new THREE.Vector3();
    vec.set(e.clientX, e.clientY, 0.5);

    return convertToCanvasSpace(vec);
  }

  const handleCanvasMousedown = (e) => {
    if (touchDisabled && e.pointerType === "touch") {
      return;
    }

    const vec = getMousePosition(e);
    let indexCount = 0;
    if (isSelectingColor || isSelectingShape) {
      var bb = canvasRef.current.getBoundingClientRect();

      vec.x = ((e.clientX - bb.left) / bb.width) * 2 - 1;
      vec.y = -((e.clientY - bb.top) / bb.height) * 2 + 1;

      const mesh = getIntersectedMesh(vec);
      if (mesh != null) {
        // todo
        syncRedValue(mesh.material.color.r);
        syncGreenValue(mesh.material.color.g);
        syncBlueValue(mesh.material.color.b);

        updateHSV(
          mesh.material.color.r,
          mesh.material.color.g,
          mesh.material.color.b
        );

        if (isSelectingShape) {
          setSelectedMesh(mesh);
        }
        syncIsSelectingShape(false);
        syncIsSelectingColor(false);
      }
    } else {
      // add point
      setPreviousPoint(vec);
      if (workingLineRef.current) {
        indexCount++;
      }
      addPoint(vec.x, vec.y);

      isMouseDownRef.current = true;
    }

    setStrokeIndexCount(indexCount);

    e.preventDefault();
  };

  const getTouchPosition = (e) => {
    var touches = Object.values(
      e.touches.length > 0 ? e.touches : e.changedTouches
    );
    var vec = new THREE.Vector3();
    vec.set(e.touches[0].clientX, e.touches[0].clientY);
    vec.set(
      touches.reduce((a, b) => ({ clientX: a.clientX + b.clientX })).clientX /
        touches.length,
      touches.reduce((a, b) => ({ clientY: a.clientY + b.clientY })).clientY /
        touches.length,
      0.5
    );
    return convertToCanvasSpace(vec);
  };

  const handleCanvasTouchstart = (e) => {
    if (isSelectingColor) return;

    const vec = getTouchPosition(e);
    setPreviousPoint(vec);
    addPoint(vec.x, vec.y);
    isMouseDownRef.current = true;
    e.preventDefault();
    e.stopPropagation();
  };

  const handleCanvasTouchmove = (e) => {
    if (isSelectingColor) return;

    if (isMouseDownRef.current) {
      const vec = getTouchPosition(e);

      setPreviousPoint(vec);

      addPoint(vec.x, vec.y);
    }
    e.preventDefault();
    e.stopPropagation();
  };

  const handleCanvasMousemove = (e) => {
    if (touchDisabled && e.pointerType === "touch") {
      return;
    }

    if (isMouseDownRef.current) {
      const vec = getMousePosition(e);

      setPreviousPoint(vec);

      addPoint(vec.x, vec.y);

      setStrokeIndexCount(strokeIndexCount + 1);
      e.preventDefault();
    }
  };

  const handleCanvasMouseup = (e) => {
    if (touchDisabled && e.pointerType === "touch") {
      return;
    }
    isMouseDownRef.current = false;

    if (autocompleteShape) {
      handleShapeConfirmed();
    }
  };

  const completeShape = (e) => {
    clearWorkingLine();
    workingShapesRef.current = [];

    let chosenMesh =
      selectedType === "line" ? workingLineMeshRef : workingMeshRef;
    let discardMesh =
      selectedType === "line" ? workingMeshRef : workingLineMeshRef;
    const color = new THREE.Color(redValue, greenValue, blueValue);

    const adjustColor = (value, delta) => {
      return Math.max(Math.min(1, +value + delta), 0);
    };

    const average = (+redValue + +greenValue + +blueValue) / 3;
    const deltaVal = average > 0.5 ? -0.02 : 0.02;
    const adjustedColor = new THREE.Color(
      adjustColor(redValue, deltaVal),
      adjustColor(greenValue, deltaVal),
      adjustColor(blueValue, deltaVal)
    );

    chosenMesh.current.material.color = adjustedColor;
    chosenMesh.current.material.opacity = 1;
    setSelectedMesh(chosenMesh.current);

    setTimeout(() => {
      chosenMesh.current.material.color = color;
      chosenMesh.current.material.opacity = 1;

      // setSelectedMesh(chosenMesh.current);
      chosenMesh.current = null;

      // meshGroupRef.current.remove(discardMesh.current);
      discardMesh.current = null;
    }, 150);
  };
  // expects vec3, returns mesh
  const getIntersectedMesh = (vec) => {
    // vec.unproject(cameraRef.current);
    raycaster.setFromCamera(new THREE.Vector2(vec.x, vec.y), cameraRef.current);
    var intersects = raycaster.intersectObjects([sceneRef.current], true);

    if (intersects.length > 0) {
      intersects.sort((a, b) => b.object.position.z - a.object.position.z);
      return intersects[0].object;
    }
  };

  const createShape = () => {
    const positions = workingLineRef.current.geometry.attributes.position;
    const count = workingLineRef.current.geometry.drawRange.count;

    const vec2Positions = [];
    for (let i = 0; i < count; i++) {
      const p = new THREE.Vector2().fromBufferAttribute(positions, i);
      vec2Positions.push(p);
    }

    // uncomment this for the heavypaint fill
    // const shapes =
    //   workingShapesRef.current == null ? [] : workingShapesRef.current;
    const shapes = [];

    const newShape = new THREE.Shape(vec2Positions);
    workingShapesRef.current = [...shapes, newShape];

    const geometry = new THREE.ShapeGeometry(workingShapesRef.current);
    // const color = new THREE.Color(
    //   redValue - 0.02,
    //   greenValue - 0.02,
    //   blueValue - 0.02
    // );
    const color = new THREE.Color(1, 1, 1);

    const material = new THREE.MeshBasicMaterial({
      color: color,
      opacity: 0.15,
      transparent: true,
    });
    const mesh = new THREE.Mesh(geometry, material);

    mesh.position.z = zCount;
    setZCount(zCount + 0.001);
    // mesh.scale.x = 500;
    // mesh.scale.y = 500;

    if (workingMeshRef.current != null) {
      meshGroupRef.current.remove(workingMeshRef.current);
    }

    if (selectedType === "fill") {
      meshGroupRef.current.add(mesh);
    }

    workingMeshRef.current = mesh;
  };

  const handleShapeConfirmed = () => {
    if (workingLineRef.current != null) {
      createShape();
      completeShape();
    }
  };

  useEffect(() => {
    window.onkeydown = (e) => {
      if (e.key === "Enter") {
        handleShapeConfirmed();
      } else if (e.key === "Escape") {
        handleUserClear();
      }
    };
  }, [handleShapeConfirmed]);

  const lineWidthTicks = 2;
  const marks = [
    {
      label: (
        <Pentagon
          className="polygon-icon"
          color="inherit"
          fontSize="medium"
        ></Pentagon>
      ),
      value: LINE_SLIDER_MIN,
    },
    {
      label: (
        <Gesture className="small-gesture-icon" fontSize="small"></Gesture>
      ),
      value: (LINE_SLIDER_MAX - LINE_SLIDER_MIN) / 2,
    },
    {
      label: (
        <Gesture className="large-gesture-icon" fontSize="large"></Gesture>
      ),
      value: LINE_SLIDER_MAX,
    },
  ];
  const start = (LINE_SLIDER_MAX - LINE_SLIDER_MIN) / 2;
  const stepSize = (LINE_SLIDER_MAX - start) / lineWidthTicks;
  for (let i = 1; i < lineWidthTicks; i++) {
    marks.push({ value: start + i * stepSize });
  }

  const handleUserClear = () => {
    clearWorkingLine();
    meshGroupRef.current.remove(workingMeshRef.current);
    meshGroupRef.current.remove(workingLineMeshRef.current);
    workingMeshRef.current = null;
    workingLineMeshRef.current = null;
    workingShapesRef.current = [];

    setSelectedMesh(prevSelectedMeshRef.current);
  };

  return (
    <div className="main-canvas">
      <div
        className="canvas-container"
        onPointerDown={handleCanvasMousedown}
        onPointerUp={handleCanvasMouseup}
        onPointerMove={handleCanvasMousemove}
        // onMouseDown={handleCanvasMousedown}
        // onMouseUp={handleCanvasMouseup}
        // onMouseMove={handleCanvasMousemove}
        // onTouchStart={handleCanvasTouchstart}
        // onTouchEnd={handleCanvasMouseup}
        // onTouchMove={handleCanvasTouchmove}
      >
        <div className="canvas-inner-container">
          <div id="canvas" ref={canvasRef} className="canvas"></div>
        </div>
      </div>

      <div className="canvas__actions">
        {/* {workingLineRef.current == null && workingMeshRef.current == null ? ( */}
        {workingLineRef.current == null ? (
          isMeshSelected ? (
            <>
              <div className="info-text">
                {isSelectingColor
                  ? "Select a color"
                  : isSelectingShape
                  ? "Select a shape to edit"
                  : "Adjust color or draw a new shape"}
              </div>
              {/* {" "} */}
              {/* <h3>Choosing color</h3>
              &emsp;
              <Button
                className="button"
                variant="contained"
                style={{ margin: 5 }}
                onClick={() => {
                  selectedMeshRef.current = null;
                  syncIsMeshSelected(false);
                }}
              >
                Done
              </Button> */}
            </>
          ) : (
            <div className="info-text">Click / tap to start a new shape</div>
          )
        ) : (
          <>
            <div className="info-text">
              {workingLineRef.current == null ? (
                "Add another shape or continue to color"
              ) : (
                <>
                  Drawing shape...{"  "}
                  <span
                    style={{
                      color: selectedType === "fill" ? "white" : "#ba68c8",
                      padding: "5px",
                      background:
                        selectedType === "fill" ? "#ba68c8" : "transparent",
                      border: "1px solid #ba68c8",
                      borderRadius: "8px",
                      opacity: 0.6,
                      height: "12px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSelectedType(
                        selectedType === "fill" ? "line" : "fill"
                      );
                    }}
                  >
                    {selectedType === "fill" ? "filled" : "no fill"}
                  </span>
                </>
              )}
            </div>
            <div className="action-buttons">
              {workingLineRef.current == null ? (
                <>
                  <Button
                    style={{ margin: 5 }}
                    className="button"
                    variant="contained"
                    onMouseDown={(e) => {
                      handleShapeConfirmed();
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    onClick={() => {
                      // if (workingLineRef.current != null) {
                      //   createShape();
                      // }
                      // completeShape();
                    }}
                  >
                    Done
                  </Button>
                </>
              ) : (
                <>
                  {strokeIndexCount === 0 ? (
                    <Button
                      className="clear-button"
                      style={{ margin: 5 }}
                      onClick={() => {
                        handleUserClear();
                      }}
                    >
                      Clear
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        removePoints(strokeIndexCount);
                        setStrokeIndexCount(0);
                      }}
                    >
                      Undo
                    </Button>
                  )}

                  <Button
                    className="done-button"
                    variant="contained"
                    endIcon={
                      selectedType === "fill" ? (
                        <Pentagon></Pentagon>
                      ) : (
                        <PentagonOutlined></PentagonOutlined>
                      )
                    }
                    onClick={() => {
                      createShape();
                      completeShape();
                    }}
                  >
                    Done
                  </Button>
                </>
              )}
            </div>
            {/* <div className="fill-line-slider">
              <Slider
                min={LINE_SLIDER_MIN}
                // max={LINE_SLIDER_MAX}
                max={LINE_SLIDER_MAX}
                size="small"
                step={null}
                value={sliderValue}
                onChange={(e) => {
                  setSliderValue(e.target.value);
                }}
                marks={marks}
              ></Slider>
            </div> */}
          </>
        )}
      </div>
    </div>
  );
}

export default ArtCanvas;
