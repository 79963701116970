import {
  CircularProgress,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  ToggleButtonGroup,
  ToggleButton,
  Switch,
  FormControlLabel,
  Skeleton,
} from "@mui/material";
import ArtCanvas from "../Canvas";
import * as THREE from "three";
import { useEffect, useState, useRef } from "react";
import "./SketchPage.css";
import {
  APP_NAME,
  APP_NAME_CAP,
  SLIDER_STEP_SIZE,
} from "../../utils/constants";
import {
  Colorize,
  Download,
  HelpOutline,
  Image,
  Lightbulb,
  LightbulbCircleOutlined,
  LocationSearching,
  Restore,
  Save,
  UploadFile,
} from "@mui/icons-material";
import { ChromePicker, HuePicker } from "react-color";
import { HSVtoRGB, RGBtoHSV } from "../../utils/graphics";

function SketchPage() {
  const getRandomOffset = () => {
    return Math.random() * 0.4 - 0.2;
  };

  const [redSliderValue, setRedSliderValue] = useState(0.5 + getRandomOffset());
  const [greenSliderValue, setGreenSliderValue] = useState(
    0.5 - getRandomOffset()
  );
  const [blueSliderValue, setBlueSliderValue] = useState(
    0.5 + getRandomOffset()
  );

  const [hueSliderValue, setHueSliderValue] = useState(0.5 + getRandomOffset());
  const [satSliderValue, setSatSliderValue] = useState(0.5 - getRandomOffset());
  const [valueSliderValue, setValueSliderValue] = useState(
    0.5 + getRandomOffset()
  );

  const [isMeshSelected, setIsMeshSelected] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSelectingColor, setIsSelectingColor] = useState(false);
  const [isSelectingShape, setIsSelectingShape] = useState(false);

  const [isOptionsDialogOpen, setIsOptionsDialogOpen] = useState(false);

  const [isTutorialDialogOpen, setIsTutorialDialogOpen] = useState(false);

  const [resettingCanvas, setResettingCanvas] = useState(false);

  const [isViewingAboutInfo, setIsViewingAboutInfo] = useState(false);

  const [touchDisabled, setTouchDisabled] = useState(false);

  const [autocompleteShape, setAutocompleteShape] = useState(false);

  const [colorMode, setColorMode] = useState("hsv");

  const [selectedFile, setSelectedFile] = useState(null);

  const [tutorialLoaded, setTutorialLoaded] = useState(false);

  const sceneRef = useRef(null);
  const fileInputRef = useRef(null);

  const rendererRef = useRef(null);

  useEffect(() => {
    setTimeout(() => setIsLoaded(true), 1000);

    setTimeout(() => {
      const userHasVisited = localStorage.getItem("userHasVisitedColorfill");
      if (userHasVisited === "true") {
        return;
      }
      setIsTutorialDialogOpen(true);
      localStorage.setItem("userHasVisitedColorfill", "true");
    }, 1500);
  }, []);

  useEffect(() => {
    const { r, g, b } = HSVtoRGB(
      hueSliderValue,
      satSliderValue,
      valueSliderValue
    );
    setRedSliderValue(r / 255);
    setGreenSliderValue(g / 255);
    setBlueSliderValue(b / 255);
  }, [hueSliderValue, satSliderValue, valueSliderValue]);

  useEffect(() => {
    if (colorMode === "hsv") {
      updateHSV(redSliderValue, greenSliderValue, blueSliderValue);
    }
  }, [colorMode]);

  const updateHSV = (r, g, b) => {
    const { h, s, v } = RGBtoHSV(r * 255, g * 255, b * 255);

    setHueSliderValue(h);
    setSatSliderValue(s);
    setValueSliderValue(v);
  };

  // function saveCanvas() {
  //   // clearCanvas();

  //   // clearDraw();
  //   // render();

  //   var img = document.getElementById("canvas").toDataURL("image/png");

  //   var isTouchscreen =
  //     getComputedStyle(
  //       document.querySelectorAll(
  //         '.instructions.active[data-device="touchscreen"]'
  //       )[0],
  //       null
  //     ).display == "block";
  //   if (isTouchscreen) {
  //     // can't save image bc ios doesn't like filesystem access
  //     var w = window.open("about:blank", "image from canvas");
  //     w.document.write('<img src="' + img + '" alt="from canvas"/>');
  //   } else {
  //     document.getElementById("download").setAttribute("href", img);
  //     document
  //       .getElementById("download")
  //       .setAttribute(
  //         "download",
  //         "color-sandbox-" + String(new Date().getTime()) + ".png"
  //       );
  //   }
  // }

  // useEffect(() => {
  //   const initial = 1500;
  //   const interval = 100;
  //   const step = 0.2;

  //   setTimeout(() => setRedSliderValue(redSliderValue + step), initial);
  // }, [isLoaded]);

  // useEffect(() => {
  //   window.addEventListener("beforeunload", function (e) {
  //     var confirmationMessage =
  //       "It looks like you have been editing something. " +
  //       "If you leave before saving, your changes will be lost.";

  //     (e || window.event).returnValue = confirmationMessage; //Gecko + IE
  //     return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
  //   });
  // }, []);

  function exportData(data) {
    var exportLink = document.createElement("a");
    exportLink.setAttribute(
      "href",
      "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data))
    );
    // exportLink.appendChild(document.createTextNode("mySketchFile.json"));
    exportLink.setAttribute("download", "mySketchFile.json");

    exportLink.click();

    // console.log(exportLink);
  }

  function exportImage(canvas) {
    // var exportLink = document.createElement("a");
    // exportLink.setAttribute(
    //   "href",
    //   canvas.toDataURL("image/png").replace("image/png", "image/octet-stream")
    // );
    // exportLink.setAttribute("download", "mySketchFile.png");
    // exportLink.click();

    // var image = document.createElement("img");
    // image.src = canvas.toDataURL("image/png");
    // .replace("image/png", "image/octet-stream");

    // var w = window.open(canvas.toDataURL("image/png"));
    // w.document.write(image.outerHTML);
    const img = canvas.toDataURL("image/png");
    console.log(img);
    var w = window.open("about:blank", "image from canvas");
    w.document.write('<img src="' + img + '" alt="from canvas"/>');
  }

  return (
    <>
      <div className="sketch-page">
        {!isLoaded && (
          <div className="loading-screen">
            <div className="title">{APP_NAME}</div>
            <br></br>
            <CircularProgress
              className="progress"
              color="secondary"
              size={30}
            ></CircularProgress>
          </div>
        )}
        <div className="art-canvas-container">
          <ArtCanvas
            redValue={redSliderValue}
            greenValue={greenSliderValue}
            blueValue={blueSliderValue}
            syncRedValue={setRedSliderValue}
            syncGreenValue={setGreenSliderValue}
            syncBlueValue={setBlueSliderValue}
            isMeshSelected={isMeshSelected}
            syncIsMeshSelected={setIsMeshSelected}
            syncIsLoaded={setIsLoaded}
            isSelectingColor={isSelectingColor}
            syncIsSelectingColor={setIsSelectingColor}
            isSelectingShape={isSelectingShape}
            syncIsSelectingShape={setIsSelectingShape}
            resettingCanvas={resettingCanvas}
            syncResettingCanvas={setResettingCanvas}
            touchDisabled={touchDisabled}
            sceneRef={sceneRef}
            selectedFile={selectedFile}
            rendererRef={rendererRef}
            updateHSV={updateHSV}
            autocompleteShape={autocompleteShape}
          ></ArtCanvas>
        </div>
        <div
          className="settings-container"
          onClick={() => {
            if (isSelectingColor) {
              setIsSelectingColor(false);
            }
            if (isSelectingShape) {
              setIsSelectingShape(false);
            }
          }}
        >
          <div className="settings-top-container">
            <div className="sliders-container">
              {isMeshSelected &&
                (colorMode === "color" ? (
                  <ChromePicker
                    color={{
                      r: redSliderValue * 256,
                      g: greenSliderValue * 256,
                      b: blueSliderValue * 256,
                    }}
                    onChange={(color) => {
                      setRedSliderValue(color.rgb.r / 256);
                      setGreenSliderValue(color.rgb.g / 256);
                      setBlueSliderValue(color.rgb.b / 256);
                    }}
                    disableAlpha={true}
                  ></ChromePicker>
                ) : colorMode === "rgb" ? (
                  <>
                    {" "}
                    <input
                      value={redSliderValue}
                      min={0}
                      max={1}
                      step={SLIDER_STEP_SIZE}
                      onChange={(e) => {
                        setRedSliderValue(e.target.value);
                      }}
                      type="range"
                      className="rgb-slider red-slider"
                      disabled={isSelectingColor || isSelectingShape}
                    ></input>
                    <input
                      value={greenSliderValue}
                      min={0}
                      max={1}
                      step={SLIDER_STEP_SIZE}
                      onChange={(e) => {
                        setGreenSliderValue(e.target.value);
                      }}
                      type="range"
                      className="rgb-slider green-slider"
                      disabled={isSelectingColor || isSelectingShape}
                    ></input>
                    <input
                      value={blueSliderValue}
                      min={0}
                      max={1}
                      step={SLIDER_STEP_SIZE}
                      onChange={(e) => {
                        setBlueSliderValue(e.target.value);
                      }}
                      type="range"
                      className="rgb-slider blue-slider"
                      disabled={isSelectingColor || isSelectingShape}
                    ></input>
                  </>
                ) : colorMode === "hsv" ? (
                  <>
                    <input
                      value={hueSliderValue}
                      min={0}
                      max={1}
                      step={SLIDER_STEP_SIZE}
                      onChange={(e) => {
                        setHueSliderValue(e.target.value);
                      }}
                      type="range"
                      className="hue-slider hsv-slider rgb-slider"
                      disabled={isSelectingColor || isSelectingShape}
                    ></input>
                    <input
                      value={satSliderValue}
                      min={0}
                      max={1}
                      step={SLIDER_STEP_SIZE}
                      onChange={(e) => {
                        setSatSliderValue(e.target.value);
                      }}
                      type="range"
                      style={{
                        backgroundImage: `linear-gradient(to right, hsl(${Math.floor(
                          hueSliderValue * 360
                        )},0%,${50}%), hsl(${Math.floor(
                          hueSliderValue * 360
                        )},100%,${50}%))`,
                      }}
                      className="saturation-slider hsv-slider rgb-slider"
                      disabled={isSelectingColor || isSelectingShape}
                    ></input>
                    <input
                      value={valueSliderValue}
                      min={0}
                      max={1}
                      step={SLIDER_STEP_SIZE}
                      onChange={(e) => {
                        setValueSliderValue(e.target.value);
                      }}
                      type="range"
                      className="value-slider hsv-slider rgb-slider"
                      disabled={isSelectingColor || isSelectingShape}
                    ></input>
                  </>
                ) : (
                  ""
                ))}

              {/* {isMeshSelected && ( */}
            </div>
            <div className="buttons-container">
              {/* <IconButton style={{ color: "#555" }} className="settings-button">
            <Settings fontSize="medium"></Settings>
          </IconButton> */}
              <IconButton
                className="colorpick-button"
                style={{ visibility: isMeshSelected ? "visible" : "hidden" }}
                onClick={() => {
                  setIsSelectingColor(true);
                }}
              >
                <Colorize
                  color={isSelectingColor ? "secondary" : "primary"}
                  fontSize="large"
                ></Colorize>
              </IconButton>
              <div
                className="color-preview"
                style={{
                  background: `rgb(${[
                    redSliderValue * 256,
                    greenSliderValue * 256,
                    blueSliderValue * 256,
                  ].join(",")})`,
                  visibility: isMeshSelected ? "visible" : "hidden",
                }}
              ></div>
              <IconButton
                className="shapepick-button"
                style={{ visibility: isMeshSelected ? "visible" : "hidden" }}
                onClick={() => {
                  setIsSelectingShape(true);
                }}
              >
                <LocationSearching>
                  color={isSelectingColor ? "secondary" : "primary"}
                  fontSize="large" >
                </LocationSearching>
              </IconButton>
            </div>
          </div>
          <div className="settings-button-container">
            <Button
              onClick={() => {
                setIsOptionsDialogOpen(true);
              }}
              style={{
                color: "#666",
              }}
            >
              options
            </Button>
          </div>
        </div>
        {/* <div
        className="color-preview"
        style={{
          background: `rgb(${[
            redSliderValue * 256,
            greenSliderValue * 256,
            blueSliderValue * 256,
          ].join(",")})`,
        }}
      ></div> */}
      </div>
      <Dialog open={isOptionsDialogOpen}>
        <DialogTitle>
          <div className="options-title">
            {isViewingAboutInfo ? `ABOUT ${APP_NAME_CAP}` : "OPTIONS"}
          </div>
        </DialogTitle>
        <Divider></Divider>
        {isViewingAboutInfo ? (
          <DialogContent>
            <h4>Welcome to {APP_NAME}!</h4>
            <p>
              {APP_NAME} is a painting app for light-weight color sketches, for
              when you're on the bus, waiting for potatoes to bake, or taking a
              break to play with colors. After painting outside with gouache +
              pencil, I wanted to recreate a comparable experience on mobile,
              with few buttons, no layers / erasing, only sketching and laying
              down color.
            </p>
            <p>
              The interface design draws inspiration from{" "}
              <a href="https://narobins.github.io/drawtools/colorsandbox.html">
                Color Sandbox
              </a>{" "}
              and <a href="https://www.heavypoly.com/heavypaint">HeavyPaint</a>,
              and is created with <a href="https://reactjs.org/">React.js</a>{" "}
              and <a href="https://threejs.org/">Three.js</a>.
            </p>
            <br></br>
            <img className="painting" src="edgar_payne_study.jpg" />
            <div className="painting-caption">
              Made in {APP_NAME} (Edgar Payne color study)
            </div>
            <h5>Contact</h5>
            <p>
              Questions or feedback? Please reach out to{" "}
              <a href="mailto:ellen@ellenart.me">ellen@ellenart.me</a>
            </p>
          </DialogContent>
        ) : (
          <DialogContent>
            <div className="dialog-option-buttons">
              <Button
                variant="outlined"
                className="about-button"
                startIcon={<Lightbulb></Lightbulb>}
                onClick={() => {
                  setIsTutorialDialogOpen(true);
                  setIsOptionsDialogOpen(false);
                }}
              >
                Tutorial
              </Button>
              <Button
                variant="outlined"
                className="reset-button"
                startIcon={<HelpOutline></HelpOutline>}
                onClick={() => {
                  setIsViewingAboutInfo(true);
                }}
              >
                About {APP_NAME}
              </Button>
              <Button
                variant="outlined"
                className="reset-button"
                startIcon={<Restore></Restore>}
                onClick={() => {
                  setResettingCanvas(true);
                  setIsOptionsDialogOpen(false);
                }}
              >
                Reset canvas
              </Button>
              {/* <Button
                variant="outlined"
                className="reset-button"
                startIcon={<UploadFile></UploadFile>}
                onClick={() => {
                  fileInputRef.current.click();
                }}
              >
                Load canvas
              </Button> */}
              {/* <Button
                variant="outlined"
                className="reset-button"
                startIcon={<Save></Save>}
                onClick={() => {
                  const sceneData = sceneRef.current.toJSON();
                  exportData(sceneData);
                  setIsOptionsDialogOpen(false);
                }}
              >
                Save canvas
              </Button> */}
              <Button
                variant="outlined"
                className="reset-button"
                startIcon={<Image></Image>}
                onClick={() => {
                  exportImage(rendererRef.current.domElement);
                }}
              >
                Export PNG
              </Button>

              <ToggleButtonGroup
                className="color-mode-select"
                value={colorMode}
                color="primary"
                onChange={(event, value) => {
                  setColorMode(event.target.value);
                }}
              >
                <ToggleButton value="rgb">RGB</ToggleButton>
                <ToggleButton value="hsv">HSV</ToggleButton>
                <ToggleButton value="color">Color box</ToggleButton>
              </ToggleButtonGroup>
              <br></br>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    className="stylus-mode-toggle"
                    checked={touchDisabled}
                    onChange={() => {
                      setTouchDisabled(!touchDisabled);
                    }}
                  ></Switch>
                }
                label={touchDisabled ? "stylus mode on" : "stylus mode off"}
              />
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    className="stylus-mode-toggle"
                    checked={autocompleteShape}
                    onChange={() => {
                      setAutocompleteShape(!autocompleteShape);
                    }}
                  ></Switch>
                }
                label={
                  autocompleteShape
                    ? "autocomplete shape on"
                    : "autocomplete shape off"
                }
              />

              {/* <Button
              onClick={() => {
                // saveCanvas();
              }}
            >
              Export image
            </Button> */}
            </div>
          </DialogContent>
        )}
        {/* <Divider></Divider> */}
        <DialogActions>
          <Button
            onClick={() => {
              setIsOptionsDialogOpen(false);
              setIsViewingAboutInfo(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isTutorialDialogOpen}>
        <DialogTitle>Colorfill Tutorial</DialogTitle>
        <DialogContent className="tutorial-content">
          <p style={{ textAlign: "center" }}>
            Draw a shape, press DONE, choose a color.
          </p>
          {!tutorialLoaded && (
            <Skeleton variant="rectangular" width={300} height={300} />
          )}

          <img
            className="demo-gif"
            style={{ height: tutorialLoaded ? 320 : 0 }}
            src="demo.gif"
            alt="loading..."
            onLoad={() => {
              setTutorialLoaded(true);
            }}
          />
          {/* <div style="width:100%;height:0;padding-bottom:121%;position:relative;">
            <iframe
              src="https://giphy.com/embed/hxWlCpjDR8hJMDgWhc"
              width="100%"
              height="100%"
              style="position:absolute"
              frameBorder="0"
              className="giphy-embed"
              allowFullScreen
            ></iframe>
          </div> */}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsTutorialDialogOpen(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <input
        type="file"
        id="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={(e) => {
          if (e.target.files.length === 0) return;
          const file = e.target.files[0];

          function onReaderLoad(event) {
            var obj = JSON.parse(event.target.result);
            setSelectedFile(obj);
            setIsOptionsDialogOpen(false);
          }

          var reader = new FileReader();
          reader.onload = onReaderLoad;
          reader.readAsText(file);
        }}
      />
    </>
  );
}

export default SketchPage;
